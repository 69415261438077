import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderModule } from '../header/header.module';
import { RodapeModule } from '../rodape/rodape.module';

//primeng
import { ChartModule } from 'primeng/chart';
import { DialogModule } from 'primeng/dialog';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SidebarModule } from 'primeng/sidebar';
import { SliderModule } from 'primeng/slider';
import { PaginatorModule } from 'primeng/paginator';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CheckboxModule } from 'primeng/checkbox';
import { CardModule } from 'primeng/card';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TooltipModule } from 'primeng/tooltip';
import { TableModule } from 'primeng/table';
import { NgxLoadingModule } from 'ngx-loading';
import { NgxMaskModule } from 'ngx-mask';
//import { CurrencyMaskModule } from 'ngx-currency-mask';
import { CurrencyMaskModule } from "ng2-currency-mask";
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputTextModule } from 'primeng/inputtext';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxPaginationModule } from 'ngx-pagination';

import {
    //cd
    //dados cadastrais
    CadastroComponent,
    AlterarSenhaComponent,
    DependentesComponent,
    //como fazer
    ComoFazerComponent,
    //plano
    IdentificacaoPlanoComponent,
    PerdaComponent,
    ParticipantesComponent,
    ComoComponent,

    //beneficios
    RegulamentoComponent,
    AuxilioFuneralComponent,
    LongevidadeComponent,
    TempoInvalidezComponent,
    MorteAssistidoComponent,
    MorteAtivoComponent,
	RendaTemporariaPortabilidade,
    //desligamento
    AutopatrocinioComponent,
    BpdComponent,
    PortabilidadeComponent,
    ResgateComponent,
    //tela beneficio
    SimuladorBeneficioComponent,
    TelaBeneficioComponent,
	//contribuicao
	TelaContribuicaoComponent,
    ContribuicaoExtratoCardComponent,
    ContribuicaoMensalCardComponent,
    //perfil investimento
    PerfilInvestimentoComponent,
    PerfilInvestimentoCardComponent,
    //informacoes complementares
    TextoInformacoesComplementaresComponent,
    //suspensao de contribuicao
    SuspensaoContribuicaoComponent,
    //demonstrativo
    DemonstrativoPagamentoComponent,
    DemonstrativoPagamentoCardComponent,
    CalendarioPagamentosCardComponent,
    SaldosCardComponent,
    BeneficioCardComponent,
    BeneficioViatlicioComponent,
    //ficha financeira
    FichaFinanceiraComponent,
    //informe de rendimentos
    InformeRendimentoComponent,
    //atuarias
    AtuariasComponent,
    //fibra em numeros
    PlanosCardComponent,
    TelaFibraNumerosComponent,
    //adiantamento abono
    AdiantamentoAbonoComponent,
    //prorrogação
    ProrrogacaoAdiantamentoParcialComponent,
    SolicitacaoProrrogacaoAdiantamentoParcialComponent,
    //historico cotas
    //HistoricoCotasComponent,
    //rentabilidade
    RentabilidadeComponent,
    RentabilidadeCardComponent,
    //autopatrocinio
    AutopatrocinioFinanceiroComponent,
    //institutos
    InstitutosComponent,
    InstitutosCardComponent,
    SolicitacaoInstitutosComponent,
	//prorrogacao-adiantamento-parcial;
	//ProrrogacaoAntecipacaoBeneficioComponent,
    PainelCdComponent,
    InicioCdComponent,
    BeneficioCdComponent,
    ContribuicaoCdComponent,
    PrevisaoAposentadoriaCdComponent,
    //Participantes e Beneficiarios
    ParticipantesBeneficiariosComponent
} from '.';
//beneficio auxilio funeral
import { BeneficioAuxilioFuneralComponent } from './solicitacoes/beneficio-auxilio-funeral/beneficio-auxilio-funeral.component';
//solicitacao de beneficio\import
import { SuplementacaoAposentadoriaComponent } from './solicitacoes/suplementacao-aposentadoria/suplementacao-aposentadoria.component';
//solicitacao de suspensao\import
import { SolicitacaoSuspensaoContribuicaoComponent } from './solicitacoes/suspensao-contribuicao/suspensao-contribuicao.component';
//solicitacao de abono\import
import { SolicitacaoAdiantamentoAbonoComponent } from './solicitacoes/adiantamento-abono/adiantamento-abono.component';
//certificado de participante
import { CertificadoInscricaoComponent } from './certificado-inscricao/certificado-inscricao.component';
//nomeação designado
import { NomeacaoDesignadoComponent } from './solicitacoes/nomeacao-designado/nomeacao-designado.component';
//exclusao beneficiario
import { ExclusaoBeneficiarioComponent } from './solicitacoes/exclusao-beneficiario/exclusao-beneficiario.component';
//Declaracao dependente ir
import { DeclaracaoDependenteIrComponent } from './solicitacoes/declaracao-dependente-ir/declaracao-dependente-ir.component';
//pessao politicamente exposta

import { DashboardCdRoutingModule } from './painel-cd-routing.module';
import { SharedModule } from '@fibra/fibra-shared-lib';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import {
    AuthGuardService, RoleGuardService,
    AssistidoGuardService,
    AtivoGuardService, FibraGuardService
} from '@fibra/fibra-shared-lib';


import { CalendarModule } from 'primeng/calendar';
import { InputMaskModule } from 'primeng/inputmask';
import { ToastModule } from 'primeng/toast';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { MessageService } from 'primeng/api';
import { FileUploadModule } from 'primeng/fileupload';
import { SpinnerModule } from 'primeng/spinner';

import { ComponentsModule } from '../../components/components.module';
import { TempoProgramadaComponent } from '../../components/tempo-programada';
import { InscricaoBeneficiarioComponent } from './solicitacoes/inscricao-beneficiario/inscricao-beneficiario.component';
import { AlterarPerfilInvestidorComponent } from './perfil-investimento/alterar-perfil-investidor/alterar-perfil-investidor.component';


@NgModule({
    schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
    imports: [
        RodapeModule,
        HeaderModule,
        BrowserAnimationsModule,
        BrowserModule,
        // CarouselModule,
        ChartModule,
        AutoCompleteModule,
        CheckboxModule,
        //CurrencyMaskModule,
		CurrencyMaskModule,
        DashboardCdRoutingModule,
        DialogModule,
        FormsModule,
        HttpClientModule,
        InputTextModule,
        NgxLoadingModule,
        NgxMaskModule,
        NgxPaginationModule,
        InfiniteScrollModule,
        RadioButtonModule,
        ReactiveFormsModule,
        ScrollPanelModule,
        SharedModule,
        SidebarModule,
        SliderModule,
        TableModule,
        PaginatorModule,
        TooltipModule,
        OverlayPanelModule,
        CalendarModule,
        InputMaskModule,
        ToastModule,
        MessageModule,
        MessagesModule,
        FileUploadModule,
        CardModule,
        SpinnerModule,
        ComponentsModule
    ],
    declarations: [
        //cd
        PainelCdComponent,
        InicioCdComponent,
        BeneficioCdComponent,
        ContribuicaoCdComponent,
        PrevisaoAposentadoriaCdComponent,
        //dados cadastrais
        CadastroComponent,
        AlterarSenhaComponent,
        DependentesComponent,
        DeclaracaoDependenteIrComponent,
        ExclusaoBeneficiarioComponent,
        NomeacaoDesignadoComponent,
        //como fazer
        ComoFazerComponent,
        //plano
        IdentificacaoPlanoComponent,
        PerdaComponent,
        ParticipantesComponent,
        ComoComponent,
        //beneficios
        RegulamentoComponent,
        AuxilioFuneralComponent,
        LongevidadeComponent,
        TempoInvalidezComponent,
        TempoProgramadaComponent,
        MorteAssistidoComponent,
        MorteAtivoComponent,
		RendaTemporariaPortabilidade,
        //desligamento
        AutopatrocinioComponent,
        BpdComponent,
        PortabilidadeComponent,
        ResgateComponent,
        //informacoes complementares
        TextoInformacoesComplementaresComponent,
        //suspensao de contribuicao
        SuspensaoContribuicaoComponent,
        SolicitacaoSuspensaoContribuicaoComponent,
        //beneficio
        SimuladorBeneficioComponent,
        TelaBeneficioComponent,
		//contribuicao
		TelaContribuicaoComponent,
        ContribuicaoExtratoCardComponent,
        ContribuicaoMensalCardComponent,
        //demonstrativo de pagamento
        DemonstrativoPagamentoComponent,
        DemonstrativoPagamentoCardComponent,
        CalendarioPagamentosCardComponent,
        SaldosCardComponent,
        BeneficioCardComponent,
        BeneficioViatlicioComponent,
        //ficha financeira
        FichaFinanceiraComponent,
        InformeRendimentoComponent,
        //atuarias
        AtuariasComponent,
        //fibra em numeros
		PlanosCardComponent,
        TelaFibraNumerosComponent,
        //beneficio-axilio-funeral
        BeneficioAuxilioFuneralComponent,
        //solicitacao de beneficio
        SuplementacaoAposentadoriaComponent,
        //solicitacao de inscricao de beneficiario
        InscricaoBeneficiarioComponent,
        //adiantamento abono
        AdiantamentoAbonoComponent,
        SolicitacaoAdiantamentoAbonoComponent,
        //prorrogação
        ProrrogacaoAdiantamentoParcialComponent,
        SolicitacaoProrrogacaoAdiantamentoParcialComponent,
        //historico cotas
        //HistoricoCotasComponent,
        //rentabilidade
        RentabilidadeComponent,
        RentabilidadeCardComponent,
        PerfilInvestimentoComponent,
        PerfilInvestimentoCardComponent,
        //autopatrocinio
        AutopatrocinioFinanceiroComponent,
        //certificado participante
        CertificadoInscricaoComponent,
        //institutos,
        InstitutosComponent,
        InstitutosCardComponent,
        SolicitacaoInstitutosComponent,
        //participantes e beneficiarios
        ParticipantesBeneficiariosComponent,
        AlterarPerfilInvestidorComponent,

        // TwoFactorAuthComponent,
        // TwoFactorAuthEmailComponent,
        // TwoFactorAuthSmsComponent,
    ],
    providers: [
        AuthGuardService,
        RoleGuardService,
        AtivoGuardService,
        FibraGuardService,
        AssistidoGuardService,
        MessageService
    ]
})
export class PainelCdModule { }
